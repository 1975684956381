import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)"></g>
      <circle id="Shape" stroke="currentColor" strokeWidth="5" cx="50" cy="50" r="40" />
    </g>
    <text x="33.7" y="70" fill="currentColor" fontSize="55px" fontFamily="Consolas, serif">
      B
    </text>
  </svg>
);

export default IconLoader;
