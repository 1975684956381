import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <circle id="Shape" stroke="currentColor" strokeWidth="5" cx="39.5" cy="45" r="40" />
      </g>
    </g>
    <text x="28" y="65" fill="currentColor" fontSize="50px" fontFamily="Consolas, serif">
      B
    </text>
  </svg>
);

export default IconLogo;
